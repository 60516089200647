import { Environment } from "@react-three/drei";
import { track_remote } from "./ModelsStore.js";


function StarsEnvironment({ ...props }) {
  const file = `${track_remote}/img_environment_goegap_1k.hdr`
  return <Environment background files={file}  />;
}

export default StarsEnvironment;
